function FaqSection() {
    return (
        <section id="faqsect">
            <div id="faq">
                <h2>Najczęściej zadawane pytania</h2>
            </div>

            <div id="faqelement">
                <h3>Co oferuje nowe Ocenowo?</h3>
                <p>Ocenowo to zbiór narzędzi, które pomagają w nauce. Oferujemy Tłumacz do Instaling.pl z zupełnie nowym algorytmem, który eliminuje błędy, które napotkaliście we Wulkanku. Dostępny jest również wspomagacz nauki w Lingos.pl, tryb prywatny do Testrpotal.pl oraz Podpowiedzi EduElo!</p>
            </div>

            <div id="faqelement">
                <h3>Kiedy będą dostępne wszystkie funkcje?</h3>
                <p>Wszystkie przewidziane funkcje naszej aplikacji są już dla Was dostępne! Masz jakieś propozycje? Podziel się nimi na naszym Discordzie!</p>
            </div>

            <div id="faqelement">
                <h3>Co stało się z Dzienniczkiem Ocenowo?</h3>
                <p>Od teraz Dzienniczek Ocenowo to Wulkanek, musieliśmy dokonać kilka zmian po naszej stronie aby dalej móc dostarczać Wam najlepszy dzienniczek!</p>
            </div>

            <div id="faqelement">
                <h3>Co z moja subskrypcją z Wulkanka?</h3>
                <p>Jeżeli zakupiłeś subskrypcję przed 31-wszym stycznia 2024 roku we Wulkanku (starym Dzienniczku Ocenowo), możesz się do nas zgłosić o przeniesienie Ocenowo+ do nowej aplikacji.</p>
            </div>

            <div id="faqelement">
                <h3>Co oferuje Tłumacz do Instaling.pl?</h3>
                <p>Tłumacz do Instaling.pl z zupełnie nowym algorytmem eliminuje błędy, które napotkaliście we Wulkanku. Tłumacz jest idealnym wsparciem w Twoim procesie nauki. </p>
            </div>
        </section>
    );
}

export default FaqSection;