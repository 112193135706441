import React, { useEffect } from 'react';

// import logo from '../logo.svg';
import './FabaApp.css';
import Nav from '../../components/Dzienniczek/Nav';
import Hero from '../../components/Dzienniczek/Hero';
import Function from '../../components/Dzienniczek/Function';
import OtherFunctions from '../../components/Dzienniczek/OtherFunctions';
import Download from '../../components/Dzienniczek/Download'
import Footer from '../../components/Dzienniczek/Footer';
import Discord from '../../components/Dzienniczek/Discord';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Dzienniczek() {
  useEffect(() => {
    document.title = 'Wulkanek Dzienniczek';
    const metaDescription = document.querySelector('meta[name="description"]');
    const metaIcon = document.querySelector('link[rel="icon"]');
    metaDescription.setAttribute('content', "Wulkanek to nowy i zaawansowany dziennik, który pozwoli na doświadczenie nowych możliwości, które nie znajdziesz nigdzie indziej.");
    metaIcon.setAttribute('href', "img/Dzienniczek/ikona_logo.png")
  }, []);

  return (
    <div className="App">
      <Nav />
      <Hero />
      <Function />
      <OtherFunctions />
      <Download />
      <Discord />
      <Footer />
    </div>
  );
}