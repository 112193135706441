import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faPalette } from '@fortawesome/free-solid-svg-icons';

function OtherFunctions(){
    return(
        <div className="other">
            <div className="column">
                <h3><FontAwesomeIcon icon={faCheck}/>Wsparcie do instaling.pl</h3>
                <p>
                    W twojej szkole jest instaling? W naszej aplikacji nie musisz się martwić o uzupełnianie i pamiętanie.
                </p>
            </div>
            <div className="column">
                <h3><FontAwesomeIcon icon={faPalette} />Motywy</h3>
                <p>
                    Od teraz to ty decydujesz jak będzie wyglądać twój dziennik. Będziesz miał dostęp do pełnej modyfikowania kolorów, które występują w aplikacji podczas wyświetlania konkretnych ocen.
                </p>
            </div>
        </div>
    );
}

export default OtherFunctions;