import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

function DzienniczekNav() {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    return (
        <nav>
            <div className="logo"><img src="img/Dzienniczek/wulkanekLogo.png" alt="Logo Ocenowo." />
            </div>
            <button onClick={toggleMenu}>
                <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars}/>
            </button>
            <div className={`links ${isMenuOpen ? 'open' : ''}`}>
            <a href="./">Narzędzia Ocenowo</a>
                <a href="https://play.google.com/store/apps/details?id=com.sobgog.ocenowo" target='_blank' rel="noreferrer">Google Play</a>
                <a href="https://apps.apple.com/us/app/ocenowo-dzienniczek/id6471573429?platform=iphone" target='_blank' rel="noreferrer">App Store</a>
                <a href='https://discord.gg/TCmqujhvh5' target='_blank' rel="noreferrer">Discord</a>
            </div>
        </nav>
    );
}

export default DzienniczekNav;
