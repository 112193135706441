function FeatureSection(){
    return(
        
        <section id="featuresect">
            <div id="whitespace"></div>
            <div className="feature">
                <h2>Tłumacz do Instaling.pl</h2>
                <p>Tłumacz do Instaling.pl z zupełnie nowym algorytmem eliminuje błędy! Funkcja została zaprojektowana od zera aby zapewnić Wam szybsze rozwiązywanie sesji. Zadbaliśmy również o wszelkie błędy i propozycje funkcji które zgłaszali nasi cudowni użytkownicy.</p>
            </div>
            <div className="feature">
                <h2>Wsparcie w nauce Lingos.pl</h2>
                <p>Skoncentruj się na procesie nauki, a do tego odrabiaj sesje szybciej dzięki pomocy aplikacji.</p>
            </div>
            <div className="feature">
                <h2>Tryb prywatny Testportal.pl</h2>
                <p>Masz testy na testportalu? To świetna wiadomość! Mamy dla Ciebie tryb prywatny. Dzięki nam możesz swobodnie korzystać z urządzenia bez ingerencji w Twój telefon.</p>
            </div>    
            <div className="feature">
                <h2>EduElo</h2>
                <p>Czy może być coś lepszego niż podpowiedzi do zadań? Teraz dzięki Ocenowo ułatwisz sobie rozwiązywanie zadań również na platformie EduElo!</p>
            </div>                            
        </section>
    );
}

export default FeatureSection;