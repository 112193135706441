import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

function Nav() {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };


    return (
        <header>
        <img src="img/Ocenowo/logo.png" alt="Ocenowo logo"></img>
        <div id="dzienniczek">
            <a href="/dzienniczek">
                <p>Dzienniczek</p>
            </a>
        </div>
        <div id="googleplay">
            <a href="https://play.google.com/store/apps/details?id=com.ocenowo.ocenowo" target='_blank' rel="noreferrer">
                <p>Google Play</p>
            </a>
        </div>
        <div id="appstore">
            <a href='https://apps.apple.com/pl/app/ocenowo-narz%C4%99dzia-szkolne/id6478110214?platform=iphone' target='_blank' rel="noreferrer">
                <p>App Store</p>
            </a>
        </div>
        <div id="discord">
            <a href='https://discord.gg/mdcZnSdrgQ' target='_blank' rel="noreferrer">
                <p>Discord</p>
            </a>
        </div>
        <button onClick={toggleMenu} id='mobileNav'>
                <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars}/>
        </button>
        <div className={`menu ${isMenuOpen ? 'open' : 'close'}`}>
                <a href='/dzienniczek'>
                    <div id='navIcon'>
                        <img src='../img/Ocenowo/logo.webp' alt='Ocenowo logo'></img>
                    </div>
                    <p>Dzienniczek</p>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.ocenowo.ocenowo" target='_blank' rel="noreferrer">
                <div id='navIcon'>
                        <img src='../img/Ocenowo/google-play.png' alt='Google Play Logo'></img>
                    </div>
                    <p>Google Play</p>
                </a>
                <a href='https://apps.apple.com/pl/app/ocenowo-narz%C4%99dzia-szkolne/id6478110214?platform=iphone' target='_blank' rel="noreferrer">
                <div id='navIcon'>
                        <img src='../img/Ocenowo/app-store.png' alt='Apple Store Logo'></img>
                    </div>
                    <p>App Store</p>
                </a>
                <a href='https://discord.gg/mdcZnSdrgQ' target='_blank' rel="noreferrer">
                <div id='navIcon'>
                        <img src='../img/Ocenowo/discord.png' alt='Discord Logo'></img>
                    </div>
                    <p>Discord</p>
                </a>
                <div id='navCredits'>
                    <p>&copy; Ocenowo 2025</p>
                    <a href="https://www.linkedin.com/in/martin-gogolowicz/" target="_blank" rel="noreferrer"><p>App by Martin Gogołowicz</p></a>                    
                    <p>Website by Ranpenzo</p>                    
                </div>
        </div>       
    </header>
    );
}

export default Nav;
