import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Footer(){
    return(
        <div id="footerD">
            <p>
                &copy;2024 Copyright - Wulkanek | <a href='https://sobgog.com/ocenowo/privacy.html' target='_blank'>Polityka prywatności</a> | App by <a href='https://www.linkedin.com/in/martin-gogolowicz/' target='_blank'>Martin Gogołowicz</a> | Website by <a href='https://michalfaber.com/' target='_blank'>"MNIH" </a>
            </p>
        </div>
    );
}

export default Footer;